var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"MapBrowser"},[_c('mapbox',{attrs:{"access-token":this.accessToken,"map-options":{
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-96, 37.8],
      zoom: 4,
    },"geolocate-control":{
      show: true,
      position: 'top-left',
    }},on:{"map-load":_vm.loaded,"map-zoomend":_vm.zoomend,"map-click:points":_vm.clicked,"geolocate-error":_vm.geolocateError,"geolocate-geolocate":_vm.geolocate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }