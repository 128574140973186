var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default"),_vm._v(" "),_vm._l((_vm.normalizedCategories),function(ref){
var id = ref.id;
var name = ref.name;
var description = ref.description;
var subcategories = ref.subcategories;
return _c('div',{key:id},[_c('b-collapse',{staticClass:"card",attrs:{"id":("collapse-" + id),"aria-id":("collapse-content-" + id),"open":_vm.isSelected(name),"animation":"slide"},scopedSlots:_vm._u([{key:"trigger",fn:function(collapse){return _c('div',{staticClass:"card-header",attrs:{"aria-controls":("collapse-content-" + id),"role":"button"}},[_c('p',{staticClass:"card-header-title has-text-weight-normal is-size-4"},[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(name))+"\n        ")]),_vm._v(" "),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":collapse.open ? 'angle-up' : 'angle-down'}})],1)])}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"card-content"},[(description)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(description)+" ")]):_vm._e(),_vm._v(" "),(_vm.anySelected(subcategories))?_c('input',{attrs:{"name":_vm.fieldNamePrefix,"type":"hidden"},domProps:{"value":id}}):_vm._e(),_vm._v(" "),_vm._l((subcategories),function(ref){
var subId = ref.id;
var subName = ref.name;
var subDescription = ref.description;
return _c('div',{key:subId},[_c('b-field',{staticClass:"pb-05",attrs:{"grouped":""}},[_c('b-checkbox',{attrs:{"name":_vm.fieldNamePrefix,"native-value":subId,"size":"is-medium"},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(subName)))]),_vm._v(" "),(subDescription)?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s("- " + subDescription)+" ")]):_vm._e()])],1)],1)})],2)])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }