<template>
  <b-field>
    <div class="control">
      <b-button native-type="submit" type="is-primary" size="is-medium">
        Submit
      </b-button>
    </div>
  </b-field>
</template>

<script>
export default {
}
</script>
